import React from "react";
import { useState, useEffect, useInsertionEffect } from "react";
import ReactMarkdown from 'react-markdown'


const FAQ = ({ faq, answer, index, valindex, toggleFAQ }) => {
  
  useEffect(() => {
    document.title = "Frequently Asked Questions | Summer Camp Scout";  
  }, []);


  return (
    <div
      className={"faq" + (index == valindex ? " open" : "")}
      key={valindex}
      onClick={() => toggleFAQ(valindex)}
    >
      <div className="faq-question"><ReactMarkdown>{faq}</ReactMarkdown></div>
      <div className="faq-answer"><ReactMarkdown>{answer}</ReactMarkdown></div>
    </div>
  );
};

export default FAQ;
