import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
//import useGeolocation from "react-hook-geolocation";
import React, { useState, useEffect } from "react";
import Footer from './components/footer';
import Main from './routes/main';
import Archive from './routes/Archive';
import Providers from './routes/Providers';
import Contact from './routes/contact';
import Advertising from './routes/advertising';
import Privacy from './routes/privacy';
import Cookies from './routes/cookies';
import Submit from './routes/submit';
import Sitemap from'./routes/Sitemap';
import SubmitCamps from './routes/submit-camps';
import Faq from './routes/faqs';
import News from './routes/news';
import NewsArticle from './routes/news-article';

// require('dotenv').config({ path: `.env.${process.env.NODE_ENV}` })

const query = `query {
  globalSettingsCollection(limit: 1){
      items{
        title
        notifications
        gtMid
        submitThankyou
        campListingPreviewCopy
        htmlSitemap
        menuCollection{
          items{
                title
                slug
        		}
        
        }
        resourcesMenuCollection{
          items{
                title
                slug
            		description
        		}
        }
        }
      }
    }` 


const tagManagerArgs = {
  gtmId: 'GTM-573BGJP'
}
TagManager.initialize(tagManagerArgs)

export default function App() {
  //const geolocation = useGeolocation();
  let [lon, setLon] = useState(null);
  let [lat, setLat] = useState(null);
  let [selectDistance, setSelectDistance] = useState(16);
  let [zip, setZip] = useState(null);
  let [searchTerm, setSearchTerm] = useState("");
  let [citySearch, setCitySearch] = useState("");
  let [globalData, setGlobalData] = useState(null);
  let [alert,setAlert] = useState(null);
  let [startDate, setStartDate] = useState(0);
  let [endDate, setEndDate] = useState(0);
  let [age, setAge] = useState(0);
  let [cost, setCost] = useState(100);
  let [zipcode,setZipcode] = useState(null);
  let [filter, setFilter] = useState(false);
  let [filterDescription, setFilterDescription] = useState(false);

  const [checkedDay, setCheckedDay] = useState(false);
  const [checkedNight, setCheckedNight] = useState(false);
  const [checkedAllDay, setCheckedAllDay] = useState(false);
  const [checkedAm, setCheckedAm] = useState(false);
  const [checkedPm, setCheckedPm] = useState(false);
  

  const searchCheckedDay = () => {
    if(checkedDay == false){
      console.log("false");
    setCheckedNight(false);
    setCheckedDay(true);
    }else{
      setCheckedDay(false);
    }
  }

  const searchCheckedNight = () => {
    if(checkedNight == false){
    setCheckedDay(false);
    setCheckedNight(true);
    }else{
      setCheckedNight(false);
      setCheckedNight(false);
    }
  }

  const searchCheckedAllday = () => {
    if(checkedAllDay == true){
      setCheckedAllDay(false)
    }else{
      setCheckedAllDay(true)
      setCheckedAm(false)
      setCheckedPm(false)
    }
    
  }
  const searchCheckedAm = () => {
    if(checkedAm == true){
      setCheckedAm(false)
    }else{
      setCheckedAm(true)
      setCheckedAllDay(false)
      setCheckedPm(false)
    }
    
  }
  const searchCheckedPm = () => {
    if(checkedPm == true){
      setCheckedPm(false)
    }else{
    setCheckedPm(true)
    setCheckedAm(false)
    setCheckedAllDay(false)
    }
  }
  
  function testZip(newValue) {
    console.log(newValue)
  }

  function handleSearch(newValue) {
  setSearchTerm("");
  if(newValue == "") {
  }else{
  setSearchTerm(newValue.toUpperCase());
  }
}
function ageSet(newValue) {
  setAge(newValue);
}
function costSet(newValue) {
  setCost(newValue);
}
function setStart(newValue) {
  setStartDate(newValue);
}
function setEnd(newValue) {
  setEndDate(newValue);
}
function handleCity(newValue) {
  setCitySearch(newValue);
}

function handleLatChange(newValue) {
  setLat(newValue);
}

function handleLonChange(newValue) {
  setLon(newValue);
}
function zipcodeSet(newValue) {
  setZipcode(newValue);
}

function extractFromAdress(components, type){
  for (var i=0; i<components.length; i++)
      for (var j=0; j<components[i].types.length; j++)
          if (components[i].types[j]==type) return components[i].short_name;
  return "";
}

function clearFilter(){
  setEndDate(0);
  setStartDate(0);
  setAge(0);
  setCheckedDay(false);
  setCheckedNight(false);
}
function error(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}


useEffect(() => {
  window
    .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authenticate the request
        Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
      },
      // send the GraphQL query
      body: JSON.stringify({ query }),
    })
    .then((response) => response.json())
    .then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }

      // rerender the entire component with new data
      // console.log(data.globalSettingsCollection.items[0]);
      setGlobalData(data.globalSettingsCollection.items[0]);
      setAlert(data.globalSettingsCollection.items[0].notifications)
      
    });
}, []);

useEffect(() => {
  const script = document.createElement('script');
  script.src='https://js.hs-scripts.com/23548397.js';
  script.id="hs-script-loader" 
  document.body.appendChild(script);

  
  
})  



  return (
    
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Main handleLatChange={handleLatChange} handleLonChange={handleLonChange} globalData={globalData} />}  />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/advertising" element={<Advertising />} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/cookie-settings" element={<Cookies/>} />
          <Route path="/faq" element={<Faq/>} />
          <Route exact path="/submit" element={ <Navigate replace to="/submit-camps" />}/>
          <Route path="/submit-camps" element={<SubmitCamps globalData={globalData} />} />
          <Route path="/resources" element={<News globalData={globalData} />} />
          <Route path="/resources/:name" element={<NewsArticle globalData={globalData} lat={lat} lon={lon}/>} />
          <Route path="/resources/category/:cat" element={<News globalData={globalData} />} />
          <Route path="/sitemap" element={<Sitemap globalData={globalData} />} />
          <Route path="/archive/2023" element={<Archive />} />
          <Route path="/providers" element={<Providers  />} />
          <Route exact path="/*/*" element={<Main />}  />
          <Route  path="/*" element={<Main />}  />
          
          {/* <Route path='/404' component={NotFoundView} status={'404'} /> */}

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
