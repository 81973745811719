import { Outlet, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from '../components/pageHeader';

// const query = `query{
//   pagesCollection(where: { slug: "privacy-policy" }, limit: 1){
//     items{
//       title
//       metaDescription
//       content
//       }
      
      
//     }
//   }` 

export default function Sitemap(props) {
//   const [page, setPage] = useState(null);

//   useEffect(() => {
//     window
//       .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           // Authenticate the request
//           Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
//         },
//         // send the GraphQL query
//         body: JSON.stringify({ query }),
//       })
//       .then((response) => response.json())
//       .then(({ data, errors }) => {
//         if (errors) {
//           console.error(errors);
//         }

//         // rerender the entire component with new data
//         setPage(data.pagesCollection.items[0]);
//         document.querySelector('meta[name="description"]').setAttribute("content", data.pagesCollection.items[0].metaDescription);

//       });
//   }, []);

//     useEffect(() => {
//         document.title = "Privacy Policy | Summer Camp Scout";  
//       }, []);

      if (!props.globalData) {
    return (
      <div className="wrapper">
        <main className="page-main">
        <div>Loading...</div>
        </main>
    </div>
    )
    }else {
      return (
        <div>
        <PageHeader title={"Sitemap"}/>
        <Container className="main-content">
         <Col xl={{span: 10, offset:1 }}>
       
        <div className="pageContent sitemap-list">
        <ReactMarkdown>{props.globalData.htmlSitemap}</ReactMarkdown>
        </div>
        </Col>
    </Container>
    </div>
      )
    }
    
  }